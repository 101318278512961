// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SuccessPage_pageHeader__G-Nne {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #EAECF0;
  padding-bottom: 32px;
  margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/SuccessPage/SuccessPage.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,gCAAA;EACA,oBAAA;EACA,mBAAA;AADJ","sourcesContent":["@use '../../styles/colors' as colors;\r\n\r\n.pageHeader {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-bottom: 1px solid colors.$GRAY200;\r\n    padding-bottom: 32px;\r\n    margin-bottom: 40px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `SuccessPage_pageHeader__G-Nne`
};
export default ___CSS_LOADER_EXPORT___;
