// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoTileModal_modalContainer__8-9UK {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InfoTileModal_modalInnerContainer__WFOBm {
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.InfoTileModal_textarea__4oEwQ {
  padding: 0;
  margin: 0 0 16px 0;
  width: 100%;
  height: 200px;
}

.InfoTileModal_iconButton__yQNJs {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0 2px 0 0;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/InfoTileModal/InfoTileModal.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;AACJ;;AAEA;EACI,6BAAA;EACA,YAAA;EACA,UAAA;EACA,iBAAA;EACA,eAAA;AACJ","sourcesContent":[".modalContainer {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.modalInnerContainer {\r\n    width: 300px;\r\n    height: 300px;\r\n    background-color: white;\r\n    border-radius: 8px;\r\n    padding: 8px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n\r\n.textarea {\r\n    padding: 0;\r\n    margin: 0 0 16px 0;\r\n    width: 100%;\r\n    height: 200px;\r\n}\r\n\r\n.iconButton {\r\n    background-color: transparent;\r\n    border: none;\r\n    padding: 0;\r\n    margin: 0 2px 0 0;\r\n    cursor: pointer;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `InfoTileModal_modalContainer__8-9UK`,
	"modalInnerContainer": `InfoTileModal_modalInnerContainer__WFOBm`,
	"textarea": `InfoTileModal_textarea__4oEwQ`,
	"iconButton": `InfoTileModal_iconButton__yQNJs`
};
export default ___CSS_LOADER_EXPORT___;
