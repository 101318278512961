// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditTileModal_editableTextField__55FUl {
  width: 100%;
  min-height: 130px;
}

.EditTileModal_selectsContainer__s-eGP {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/EditTileModal/EditTileModal.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,SAAA;AACJ","sourcesContent":[".editableTextField {\r\n    width: 100%;\r\n    min-height: 130px;\r\n}\r\n\r\n.selectsContainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin-top: 36px;\r\n    gap: 24px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editableTextField": `EditTileModal_editableTextField__55FUl`,
	"selectsContainer": `EditTileModal_selectsContainer__s-eGP`
};
export default ___CSS_LOADER_EXPORT___;
