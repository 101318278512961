// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManagmentPanelPage_wrapper__EW0NS {
  display: flex;
  flex-direction: column;
}

.ManagmentPanelPage_pageHeaderWrapper__Sjwt0 {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #EAECF0;
  padding-bottom: 32px;
  margin-bottom: 40px;
}

.ManagmentPanelPage_leftWrapper__Yrbsw {
  min-width: 120px;
  width: 120px;
  margin-right: 32px;
}

.ManagmentPanelPage_back__99SK\\+ {
  background-color: transparent;
  padding: 4px 8px;
  margin: 0 0 8px 0;
  width: 100px;
  border-radius: 8px;
}

.ManagmentPanelPage_rightWrapper__6Z2wf {
  width: 110%;
  box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
}

.ManagmentPanelPage_paginationContainer__6r9pY {
  margin-top: 54px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ManagmentPanelPage/ManagmentPanelPage.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;AADJ;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,gCAAA;EACA,oBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,YAAA;EACA,kBAAA;AADJ;;AAIA;EACE,6BAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;AADF;;AAIA;EACI,WAAA;EACA,0CAAA;EACA,YAAA;AADJ;;AAIA;EACI,gBAAA;AADJ","sourcesContent":["@use '../../styles/colors' as colors;\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.pageHeaderWrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-bottom: 1px solid colors.$GRAY200;\r\n    padding-bottom: 32px;\r\n    margin-bottom: 40px;\r\n}\r\n\r\n.leftWrapper {\r\n    min-width: 120px;\r\n    width: 120px;\r\n    margin-right: 32px;\r\n}\r\n\r\n.back {\r\n  background-color: transparent;\r\n  padding: 4px 8px;\r\n  margin: 0 0 8px 0;\r\n  width: 100px;\r\n  border-radius: 8px;\r\n}\r\n\r\n.rightWrapper {\r\n    width: 110%;\r\n    box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.1); \r\n    padding: 8px;\r\n}\r\n\r\n.paginationContainer {\r\n    margin-top: 54px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ManagmentPanelPage_wrapper__EW0NS`,
	"pageHeaderWrapper": `ManagmentPanelPage_pageHeaderWrapper__Sjwt0`,
	"leftWrapper": `ManagmentPanelPage_leftWrapper__Yrbsw`,
	"back": `ManagmentPanelPage_back__99SK+`,
	"rightWrapper": `ManagmentPanelPage_rightWrapper__6Z2wf`,
	"paginationContainer": `ManagmentPanelPage_paginationContainer__6r9pY`
};
export default ___CSS_LOADER_EXPORT___;
