// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KanbanPage_wrapper__4Xppm {
  display: flex;
  flex-direction: column;
}

.KanbanPage_pageHeader__BAm3X {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #EAECF0;
  padding: 32px 0;
  margin-bottom: 40px;
  align-items: center;
}

.KanbanPage_pageAddGoalHeaderWrapper__vSKQS {
  justify-content: space-between;
}

.KanbanPage_pageEditableGoalHeaderWrapper__Lskig {
}

.KanbanPage_rightWrapper__f3GLl {
  display: flex;
  flex-direction: row;
  gap: 32px;
}`, "",{"version":3,"sources":["webpack://./src/pages/KanbanPage/KanbanPage.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,gCAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;AADJ;;AAIA;EAEI,8BAAA;AADJ;;AAIA;AAAA;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AADJ","sourcesContent":["@use '../../styles/colors' as colors;\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.pageHeader {\r\n    display: flex;\r\n    flex-direction: row;\r\n    border-bottom: 1px solid colors.$GRAY200;\r\n    padding: 32px 0;\r\n    margin-bottom: 40px;\r\n    align-items: center;\r\n}\r\n\r\n.pageAddGoalHeaderWrapper {\r\n    composes: pageHeader;\r\n    justify-content: space-between;\r\n}\r\n\r\n.pageEditableGoalHeaderWrapper {\r\n    composes: pageHeader;\r\n}\r\n\r\n.rightWrapper {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 32px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `KanbanPage_wrapper__4Xppm`,
	"pageHeader": `KanbanPage_pageHeader__BAm3X`,
	"pageAddGoalHeaderWrapper": `KanbanPage_pageAddGoalHeaderWrapper__vSKQS KanbanPage_pageHeader__BAm3X`,
	"pageEditableGoalHeaderWrapper": `KanbanPage_pageEditableGoalHeaderWrapper__Lskig KanbanPage_pageHeader__BAm3X`,
	"rightWrapper": `KanbanPage_rightWrapper__f3GLl`
};
export default ___CSS_LOADER_EXPORT___;
