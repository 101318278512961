// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmployeeFilters_wrapper__CXdlK {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 20px;
}

.EmployeeFilters_filterLabelWrapper__XrXT4 {
  display: flex;
  flex-direction: column;
}

.EmployeeFilters_label__f75dN {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
}

.EmployeeFilters_inputWrapper__mCBdr {
  position: relative;
}

.EmployeeFilters_inputIcon__-sBPL {
  position: absolute;
  top: 13px;
  left: 14px;
}

.EmployeeFilters_inputNames__yiYXC {
  width: 262px;
  padding: 10px 14px 10px 42px;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 16px;
  font-weight: 400;
}

.EmployeeFilters_selectPositionWrapper__0EQR0 {
  width: 180px;
}

.EmployeeFilters_selectLevelWrapper__rjknh {
  width: 192px;
}

.EmployeeFilters_filter__xGjmh {
  width: 100%;
  padding: 10px 14px;
  outline: none;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 16px;
  font-weight: 400;
}

.EmployeeFilters_filter__xGjmh:focus {
  border: 1px solid #98A2B3;
}`, "",{"version":3,"sources":["webpack://./src/containers/EmployeeFilters/EmployeeFilters.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,aAAA;AADJ;;AAIA;EACI,aAAA;EACA,sBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,eAAA;EACA,gBAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,SAAA;EACA,UAAA;AADJ;;AAIA;EACI,YAAA;EACA,4BAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kDAAA;EACA,eAAA;EACA,gBAAA;AADJ;;AAIA;EACI,YAAA;AADJ;;AAIA;EACI,YAAA;AADJ;;AAIA;EACI,WAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kDAAA;EACA,eAAA;EACA,gBAAA;AADJ;;AAIA;EACI,yBAAA;AADJ","sourcesContent":["@use '../../styles/colors' as colors;\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 12px;\r\n    padding: 20px;\r\n}\r\n\r\n.filterLabelWrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.label {\r\n    margin-bottom: 6px;\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n}\r\n\r\n.inputWrapper {\r\n    position: relative;\r\n}\r\n\r\n.inputIcon {\r\n    position: absolute;\r\n    top: 13px;\r\n    left: 14px;\r\n}\r\n\r\n.inputNames {\r\n    width: 262px;\r\n    padding: 10px 14px 10px 42px;\r\n    line-height: 24px;\r\n    border-radius: 8px;\r\n    border: 1px solid colors.$GRAY300;\r\n    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);\r\n    font-size: 16px;\r\n    font-weight: 400;\r\n}\r\n\r\n.selectPositionWrapper {\r\n    width: 180px;\r\n}\r\n\r\n.selectLevelWrapper {\r\n    width: 192px;\r\n}\r\n\r\n.filter {\r\n    width: 100%;\r\n    padding: 10px 14px;\r\n    outline: none;\r\n    line-height: 24px;\r\n    border-radius: 8px;\r\n    border: 1px solid colors.$GRAY300;\r\n    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);\r\n    font-size: 16px;\r\n    font-weight: 400;\r\n}\r\n\r\n.filter:focus {\r\n    border: 1px solid colors.$GRAY400;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `EmployeeFilters_wrapper__CXdlK`,
	"filterLabelWrapper": `EmployeeFilters_filterLabelWrapper__XrXT4`,
	"label": `EmployeeFilters_label__f75dN`,
	"inputWrapper": `EmployeeFilters_inputWrapper__mCBdr`,
	"inputIcon": `EmployeeFilters_inputIcon__-sBPL`,
	"inputNames": `EmployeeFilters_inputNames__yiYXC`,
	"selectPositionWrapper": `EmployeeFilters_selectPositionWrapper__0EQR0`,
	"selectLevelWrapper": `EmployeeFilters_selectLevelWrapper__rjknh`,
	"filter": `EmployeeFilters_filter__xGjmh`
};
export default ___CSS_LOADER_EXPORT___;
