import React, { FC, useEffect, useState } from "react";

import { TextField } from "@mui/material";

import { useTranslation } from "react-i18next";
import EventNoteIcon from "@mui/icons-material/EventNote";

import { Button } from "../Button/Button";

import Modal from "../Modal/Modal";

import styles from "./NotesModal.module.scss";

export const NotesModal: FC = () => {
    const { t } = useTranslation();

    const maxLength = 2000;
    const [charactersLeft, setCharactersLeft] = useState<number>(maxLength);
    const [description, setDescription] = useState<string>("");
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const handleSubmit = (description: string) => console.log(description);

    useEffect(() => {
        setCharactersLeft(maxLength - description.length);
    }, [description]);

    const handleEditConfirm = async () => {
        if (!description) return;
        handleSubmit(description);
        setModalOpen(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setDescription(newValue);

        if (newValue.length <= maxLength) {
            setCharactersLeft(maxLength - newValue.length);
        }
    };

    if (!isModalOpen) {
        return (
            <Button variant="secondary" icon={<EventNoteIcon />} onClick={() => setModalOpen(!isModalOpen)}>
                {t("kanbanPage.notelModal.notes")}
            </Button>
        );
    }

    return (
        <Modal
            actionButtons={{
                onClose: {
                    action: () => {
                        setModalOpen(false);
                        setDescription("");
                    },
                },
                onSubmit: (
                    <Button onClick={handleEditConfirm} variant="secondary">
                        {t("kanbanPage.editGoalModal.save")}
                    </Button>
                ),
            }}
            isOpen={isModalOpen}
            title={t("kanbanPage.notelModal.title")}
            subTitle={t("kanbanPage.notelModal.subTitle")}
        >
            <div>
                <TextField
                    fullWidth
                    multiline
                    rows={6}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                borderColor: "#7F56D9",
                            },
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: "#7F56D9",
                        },
                    }}
                    inputProps={{
                        maxLength: maxLength,
                        style: {
                            maxHeight: "230px",
                            minHeight: "200px",
                            overflow: "scroll !important",
                        },
                    }}
                    value={description}
                    onChange={handleInputChange}
                    name="notes"
                />
                <p className={styles.charactersCounter} data-testid="charactersLeft">
                    {`${t("kanbanPage.editGoalModal.charsLeft")}: ${charactersLeft}`}
                </p>
            </div>
        </Modal>
    );
};
