// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StepLine_wrapper__NrpSP {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.StepLine_normal__9gYfH {
  background-color: #E2E4E9;
  height: 4px;
  width: 79px;
}

.StepLine_active__UTe5m {
  background-color: #E71D73;
}`, "",{"version":3,"sources":["webpack://./src/components/StepLine/StepLine.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;;AAEA;EACI,yBAAA;EACA,WAAA;EACA,WAAA;AACJ;;AACA;EAEI,yBAAA;AAEJ","sourcesContent":[".wrapper {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 12px;\r\n}\r\n\r\n.normal {\r\n    background-color: #E2E4E9;\r\n    height: 4px;\r\n    width: 79px;\r\n}\r\n.active {\r\n    composes: normal;\r\n    background-color: #E71D73;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `StepLine_wrapper__NrpSP`,
	"normal": `StepLine_normal__9gYfH`,
	"active": `StepLine_active__UTe5m StepLine_normal__9gYfH`
};
export default ___CSS_LOADER_EXPORT___;
