// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_wrapper__AJsX2 {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1024px) {
  .Layout_wrapper__AJsX2 {
    max-width: 768px;
    padding: 16px 8px;
  }
}
@media (min-width: 1025px) {
  .Layout_wrapper__AJsX2 {
    max-width: 1440px;
    padding: 32px;
  }
}

.Layout_wrapperSkipPadding__-HzQr {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.scss","webpack://./src/styles/responsive.scss","webpack://./src/styles/globals.scss"],"names":[],"mappings":"AAGA;EACI,iBAAA;EACA,kBAAA;AAFJ;ACDQ;EDCR;IAKQ,gBERK;IFSL,iBAAA;EADN;AACF;ACDQ;EDLR;IAaQ,iBEdW;IFeX,aAAA;EAHN;AACF;;AAMA;EAEI,UAAA;AAHJ","sourcesContent":["@use '../../styles/responsive' as rwd; \r\n@use '../../styles/globals' as settings; \r\n\r\n.wrapper {\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n\r\n    @include rwd.breakpoint(small) {\r\n        max-width: settings.$page-width;\r\n        padding: 16px 8px;\r\n    }\r\n    @include rwd.breakpoint(medium) {\r\n        max-width: settings.$page-width-medium;\r\n        padding: 32px;\r\n    }\r\n    @include rwd.breakpoint(large) {\r\n        max-width: settings.$page-width-large;\r\n        padding: 32px;\r\n    }\r\n}\r\n\r\n.wrapperSkipPadding {\r\n    composes: wrapper;\r\n    padding: 0;\r\n}","@mixin breakpoint($point) {\r\n    @if $point == small {\r\n        @media (max-width:  1024px) { @content; }\r\n    }\r\n    // @else if $point == medium {\r\n    //     @media (min-width:  769px) and (max-width: 1280px) { @content; }\r\n    // }\r\n    @else if $point == large {\r\n        @media (min-width:  1025px) { @content; }\r\n    }\r\n}","$page-width: 768px;\r\n$page-width-medium: 1440px;\r\n$page-width-large: 1440px;\r\n\r\n@mixin list-reset {\r\n    list-style: none;\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\n\r\n@mixin button-reset {\r\n    border: none;\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\n\r\n\r\n@mixin button() {\r\n    @include button-reset;\r\n\r\n    display: inline-flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    text-decoration: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Layout_wrapper__AJsX2`,
	"wrapperSkipPadding": `Layout_wrapperSkipPadding__-HzQr Layout_wrapper__AJsX2`
};
export default ___CSS_LOADER_EXPORT___;
