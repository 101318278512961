// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompetenceGoal_container__05cFT {
  display: flex;
  align-self: flex-start;
  height: 100%;
  width: 100%;
  border: 1px solid #E9D7FE;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.0509803922);
  border-radius: 8px;
}

.CompetenceGoal_containerEmpty__KdgQs {
  padding: 20px 24px;
  margin: auto;
}

.CompetenceGoal_description__hSfHs {
  margin-top: 52px;
  padding: 20px 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/KanbanBoard/CompetenceGoal/CompetenceGoal.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,0DAAA;EACA,kBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,YAAA;AADJ;;AAIA;EACI,gBAAA;EACA,kBAAA;AADJ","sourcesContent":["@use '../../../styles/colors.scss' as colors;\r\n\r\n.container {\r\n    display: flex;\r\n    align-self: flex-start;\r\n    height: 100%;\r\n    width: 100%;\r\n    border: 1px solid colors.$PRIMARY200;\r\n    box-shadow: 0px 1px 2px 0px #1018280D;\r\n    border-radius: 8px;\r\n}\r\n\r\n.containerEmpty {\r\n    padding: 20px 24px;\r\n    margin: auto;\r\n}\r\n\r\n.description {\r\n    margin-top: 52px;\r\n    padding: 20px 24px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CompetenceGoal_container__05cFT`,
	"containerEmpty": `CompetenceGoal_containerEmpty__KdgQs`,
	"description": `CompetenceGoal_description__hSfHs`
};
export default ___CSS_LOADER_EXPORT___;
