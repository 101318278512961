// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MatrixSummary_wrapper__fGSKe {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #EAECF0;
  padding: 0 0 42px 0;
}

.MatrixSummary_leftWrapper__YISZY {
  min-width: 320px;
  margin-right: 32px;
}

.MatrixSummary_title__9tpeY {
  font-weight: 600;
  font-size: 30px;
}

.MatrixSummary_subTitle__duSVn {
  font-weight: 400;
  font-size: 16px;
}

.MatrixSummary_midWrapper__Otbqq {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}`, "",{"version":3,"sources":["webpack://./src/containers/MatrixSummary/MatrixSummary.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gCAAA;EACA,mBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,kBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,eAAA;AADJ;;AAIA;EACI,gBAAA;EACA,eAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AADJ","sourcesContent":["@use '../../styles/colors' as colors;\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    border-bottom: 1px solid colors.$GRAY200;\r\n    padding: 0 0 42px 0;\r\n}\r\n\r\n.leftWrapper {\r\n    min-width: 320px;\r\n    margin-right: 32px;\r\n}\r\n\r\n.title {\r\n    font-weight: 600;\r\n    font-size: 30px;\r\n}\r\n\r\n.subTitle {\r\n    font-weight: 400;\r\n    font-size: 16px;\r\n}\r\n\r\n.midWrapper {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    gap: 32px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MatrixSummary_wrapper__fGSKe`,
	"leftWrapper": `MatrixSummary_leftWrapper__YISZY`,
	"title": `MatrixSummary_title__9tpeY`,
	"subTitle": `MatrixSummary_subTitle__duSVn`,
	"midWrapper": `MatrixSummary_midWrapper__Otbqq`
};
export default ___CSS_LOADER_EXPORT___;
